import React, {useEffect, useState} from "react";
import {graphql, HeadFC, navigate} from "gatsby";
import {addDoc, collection, doc, getDoc, serverTimestamp, Timestamp, updateDoc} from "firebase/firestore";
import { Router } from "@reach/router"
// @ts-ignore
import { v4 as uuidv4 } from "uuid";
import {Button} from "react-bootstrap";
import {ref, uploadBytesResumable} from "firebase/storage";
import Layout from "../../containers/Layout";
import {Order} from "../../models/Order";
import {firestore, storage} from "../../firebase";

import Seo from "../../containers/Seo";
import BankForm from "../../components/BankForm";
import {Bank} from "../../models/Bank";
import {Video} from "../../models/Video";

type RouterItemProps = {
  path?: string
}

function UploadDefault({path}: RouterItemProps): JSX.Element {
  return (
    <div>Default</div>
  );
}

type UploadOrderProps = RouterItemProps & {
  id?: string
}

function UploadOrder({path, id}: UploadOrderProps): JSX.Element {
  const [loaded, setLoaded] = useState<boolean>(false);
  const [order, setOrder] = useState<Order|null>(null);
  const [file, setFile] = useState<File|null>(null);
  const [uploading, setUploading] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);
  const [validated, setValidated] = useState<boolean>(false);

  useEffect(() => {
    const docRef = doc(firestore, "orders", id!);
    getDoc(docRef).then((snapshot) => {
      if (snapshot.exists()) {
        const _order = snapshot.data() as Order;

        if (_order.state != "available") {
          return navigate("/upload/404");
        }

        setOrder(_order);
        setLoaded(true);
        if (_order.payment == "AMAZON") {
          setValidated(true);
        }
      } else {
        navigate("/upload/404");
      }
    });
  }, [id]);

  const onUpload = () => {
    setUploading(true);
    const extension = file!.name.split('.').pop();
    const fileName = `${id}.${extension}`;
    const filePath = `videos/${fileName}`;
    const storageRef = ref(storage, filePath);
    const task = uploadBytesResumable(storageRef, file as Blob);
    task.on("state_changed", snapshot => {
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      setProgress(progress);
    });
    task.then(snapshot => {
      const video = {
        createdAt: serverTimestamp() as Timestamp,
        path: filePath,
        order: doc(firestore, `orders/${id}`)
      } as Video;

      updateDoc(doc(firestore, "orders", id!), {state: "uploaded"});
      addDoc(collection(firestore, "videos"), video).then(snapshot => {
        setUploading(false);
        return navigate("/upload/complete");
      });
    });
  };

  const onBankSubmit = (bank: Bank) => {
    const ref = doc(firestore, "orders", id!);
    updateDoc(ref, bank).then(() => {
      setValidated(true);
    });
  };

  if (!loaded) {
    return (
      <div className="container">
        <div className="warning-bg">
          Loading
        </div>
      </div>
    );
  }

  return (
    <div className="container">
      <div className="form-table">
        <div className="form-row">
          <a href="/guildeline.pdf">撮影ガイドラインはこちら</a>
        </div>
      </div>

      <div className="form-table">
        <div className="form-row">
          <div className="form-row-label">査定ID</div>
          <div className="form-row-control">{id?.substring(0, 8)}</div>
        </div>
        <div className="form-row">
          <div className="form-row-label">お支払い方法</div>
          <div className="form-row-control">{order!.payment == "CASH" ? "銀行振込" : "Amazonギフト券"}</div>
        </div>
      </div>

      {order!.payment == "CASH" && (
        <>
          <p>振込先口座を登録してください</p>
          <BankForm onSubmit={onBankSubmit} />
        </>
      )}

      {validated && (
        <>
          <div className="warning-bg mt-5">
            <form>
              <div className="mb-3">
                <label htmlFor="formFile" className="form-label">動画を選択してください</label>
                <input className="form-control" type="file" id="formFile" accept=".mp4,.mpg,.mpeg,.mov,.wmv,video/*" onChange={(e) => {
                  if (e.target.files!.length > 0) {
                    setFile(e.target.files![0]);
                  }
                }} disabled={uploading} />
              </div>
            </form>

            {uploading && (
              <div>
                <div className="progress">
                  <div className="progress-bar progress-bar-striped bg-danger" style={{width: `${progress}%`}} />
                </div>
              </div>
            )}
          </div>

          <div className="d-grid">
            <Button variant="success" disabled={!file || uploading} onClick={onUpload}>アップロードする</Button>
          </div>
        </>
      )}

    </div>
  );
}

function UploadComplete({path}: RouterItemProps): JSX.Element {
  return (
    <div className="container">
      <div className="warning-bg">
        <h2>アップロード完了</h2>
        <p>
          アップロードが完了いたしました。ご対応いただきありがとうございます。<br />
          これから動画のチェックを行い、問題がなければ速やかにお支払い手続きを進めさせていただきます。<br />
          動画に不備がある場合は再送をお願いする場合があります。<br />
          チェックが完了した時点でメールをお送りいたしますので、確認いただくようお願いいたします。
        </p>
        <h3>銀行振込でのお支払い</h3>
        <p>
          チェック完了後、振り込み手続きを行います。<br />
          土日・祝日の場合は翌営業日の入金となる場合があります。また、金融機関によってはご入金までに時間がかかる場合がございます。ご不明な点は各金融機関にお問い合わせください。<br />
          振り込み手続きが完了した時点でお知らせのメールをお送りします。
        </p>
        <h3>Amazonギフト券でのお支払い</h3>
        <p>
          チェック完了後、AmazonよりEメールタイプギフト券送付の手続きを行います。<br />
          メール到着までに24時間程度かかる場合がありますので、しばらくお待ちいただくようお願いいたします。<br />
          メールが届かない場合はお問い合わせください。Amazonの注文履歴で現在の状況を確認いたします。
        </p>
      </div>
    </div>
  );
}

function UploadNotFound({path}: RouterItemProps) {
  return (
    <div className="container">
      <div className="warning-bg">
        この査定は取引が完了しているか、有効期限が切れています。<br />
        データが削除されておりますので、はじめから査定申し込みをお願いいたします。
      </div>
    </div>
  );
}

function UploadPage(): JSX.Element {
  return (
    <Layout>
      <main>
        <div className="hero-unit">
          <h1>動画アップロード</h1>
          <div className="zag"/>
        </div>

        <Router basepath="/upload">
          <UploadComplete path="/complete" />
          <UploadNotFound path="/404" />
          <UploadOrder path="/:id" />
          <UploadDefault path="/" />
        </Router>
      </main>
    </Layout>
  );
}

export const query = graphql`
  query UploadQuery {
    site {
      siteMetadata {
        title
        description
        twitter
        siteUrl
      }
    }
  }
`

export const Head: HeadFC = ({data}: {data: any}) => (
  <Seo data={data}>
    <title id="title">動画アップロード | {data.site.siteMetadata.title}</title>
    <meta id="description" name="description" content={"動画のアップロードはこちらのページからお願いいたします"} />
  </Seo>
);

export default UploadPage;
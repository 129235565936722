import {Form} from "react-bootstrap";
import React from "react";
import {SubmitHandler, useForm} from "react-hook-form";
import {Bank, schema} from "../models/Bank";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";

function BankForm({onSubmit}: {onSubmit:SubmitHandler<Bank>}): JSX.Element {
  const {register, handleSubmit, watch, formState: {errors}} = useForm<Bank>({
    resolver: yupResolver(schema),
    defaultValues: {
      bankName: "",
      bankBranchName: "",
      bankBranchCode: "",
      bankAccountType: "普通",
      bankNumber: "",
      bankAccountHolder: ""
    }
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="form-table mb-3">
        <div className={"form-row " + (errors.bankName ? "is-invalid" : "")}>
          <div className="form-row-label">
            <Form.Label htmlFor="bankName">銀行名</Form.Label>
          </div>
          <div className="form-row-control">
            <input id="bankName" className={"form-control " + (errors.bankName ? "is-invalid" : "")}
                   placeholder="例: 三菱UFJ銀行" type="text" {...register("bankName", {
              required: {
                value: true,
                message: "必須項目です"
              }
            })} />
          </div>
        </div>
        {errors.bankName && (
          <div className="invalid-feedback">{errors.bankName?.message}</div>
        )}

        <div className={"form-row " + (errors.bankBranchName ? "is-invalid" : "")}>
          <div className="form-row-label">
            <Form.Label htmlFor="bankBranchName">支店名</Form.Label>
          </div>
          <div className="form-row-control">
            <input id="bankBranchName" className={"form-control " + (errors.bankBranchName ? "is-invalid" : "")}
                   placeholder="例: あおぞら支店" type="text" {...register("bankBranchName", {
              required: {
                value: true,
                message: "必須項目です"
              }
            })} />
          </div>
        </div>
        {errors.bankBranchName && (
          <div className="invalid-feedback">{errors.bankBranchName?.message}</div>
        )}

        <div className={"form-row " + (errors.bankBranchCode ? "is-invalid" : "")}>
          <div className="form-row-label">
            <Form.Label htmlFor="bankBranchCode">支店番号</Form.Label>
          </div>
          <div className="form-row-control">
            <input id="bankBranchCode" className={"form-control " + (errors.bankBranchCode ? "is-invalid" : "")}
                   placeholder="例: 003" type="text" {...register("bankBranchCode", {
              required: {
                value: true,
                message: "必須項目です"
              }
            })} />
          </div>
        </div>
        {errors.bankBranchCode && (
          <div className="invalid-feedback">{errors.bankBranchCode?.message}</div>
        )}

        <div className={"form-row " + (errors.bankAccountType ? "is-invalid" : "")}>
          <div className="form-row-label">
            <Form.Label htmlFor="bankAccountType">口座種別</Form.Label>
          </div>
          <div className="form-row-control">
            <select id="hair" className="form-select" {...register("bankAccountType", {required: true})}>
              <option value="普通">普通</option>
              <option value="当座">当座</option>
            </select>
          </div>
        </div>
        {errors.bankAccountType && (
          <div className="invalid-feedback">{errors.bankAccountType?.message}</div>
        )}

        <div className={"form-row " + (errors.bankNumber ? "is-invalid" : "")}>
          <div className="form-row-label">
            <Form.Label htmlFor="bankNumber">口座番号</Form.Label>
          </div>
          <div className="form-row-control">
            <input id="bankNumber" className={"form-control " + (errors.bankNumber ? "is-invalid" : "")}
                   placeholder="例: 1234567" type="text" {...register("bankNumber", {
              required: {
                value: true,
                message: "必須項目です"
              }
            })} />
          </div>
        </div>
        {errors.bankNumber && (
          <div className="invalid-feedback">{errors.bankNumber?.message}</div>
        )}

        <div className={"form-row " + (errors.bankAccountHolder ? "is-invalid" : "")}>
          <div className="form-row-label">
            <Form.Label htmlFor="bankAccountHolder">名義人名</Form.Label>
          </div>
          <div className="form-row-control">
            <input id="bankAccountHolder" className={"form-control " + (errors.bankAccountHolder ? "is-invalid" : "")}
                   placeholder="例: ワキタヨシオ" type="text" {...register("bankAccountHolder", {
              required: {
                value: true,
                message: "必須項目です"
              }
            })} />
          </div>
        </div>
        {errors.bankAccountHolder && (
          <div className="invalid-feedback">{errors.bankAccountHolder?.message}</div>
        )}
      </div>

      <div className="d-grid">
        <button type="submit" className="btn btn-success">振込先口座を登録する</button>
      </div>
    </form>
  );
}

export default BankForm;
import * as yup from "yup";
// import type {Timestamp} from "firebase/firestore";
import {jaJP} from "./locale";

yup.setLocale(jaJP);

export declare type Bank = {
  bankName: string,
  bankBranchName: string,
  bankBranchCode: string,
  bankAccountType: string,
  bankNumber: string,
  bankAccountHolder: string,
}

export const schema = yup.object({
  bankName: yup.string().required(),
  bankBranchName: yup.string().required(),
  bankBranchCode: yup.string().required(),
  bankAccountType: yup.string().required(),
  bankNumber: yup.string().required(),
  bankAccountHolder: yup.string().required()
}).required();